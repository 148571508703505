body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-noto-sans: 'Noto Sans';
  --font-bodoni-72-oldstyle: 'Bodoni 72 Oldstyle';
  --font-bodoni-72: 'Bodoni 72';
  --font-inter: 'Inter';
  --font-geomanist: 'Geomanist';
  --font-spoqa-han-sans-neo: 'Spoqa Han Sans Neo';
  --font-geomanist-ultra: 'Geomanist Ultra';

  /* font sizes */
  --font-size-smi: 13px;
  --font-size-sm: 14px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-mid: 17px;
  --font-size-lg: 18px;
  --font-size-lgi: 19px;
  --font-size-xl: 20px;
  --font-size-2xl: 21px;
  --font-size-3xl: 22px;
  --font-size-5xl: 24px;
  --font-size-6xl: 25px;
  --font-size-7xl: 26px;
  --font-size-8xl: 27px;
  --font-size-9xl: 28px;
  --font-size-10xl: 29px;
  --font-size-11xl: 30px;
  --font-size-13xl: 32px;
  --font-size-15xl: 34px;
  --font-size-16xl: 35px;
  --font-size-23xl: 42px;
  --font-size-24xl: 43px;
  --font-size-26xl: 45px;
  --font-size-28xl: 47px;
  --font-size-31xl: 50px;
  --font-size-36xl: 55px;
  --font-size-41xl: 60px;
  --font-size-49xl: 68px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-dimgray: #4e4e4e;
  --color-palegreen: #89ffa5;
  --color-limegreen: #00d308;
  --color-limegreen-100: #19d959;
  // --color-limegreen-100: #76f15c;
  --color-limegreen-200: #00d308;
  --color-gainsboro: #ddd;
  --color-gainsboro-100: #ddd;
  --color-gainsboro-200: #d9d9d9;
  --color-whitesmoke: #f6f8f9;
  --color-whitesmoke-100: #f6f8f9;
  --color-whitesmoke-200: #f5f5f5;
  --color-header-bg: #191919;
  --color-gray: #8f8f8f;
  --color-gray-100: #929292;
  --color-gray-200: #818181;
  --color-lightgray: #d5d5d5;
  --color-darkgray: #b4b4b4;
  --color-darkgray-100: #ababab;
  --color-darkgray-200: #8b9e8b;
  --color-darkslategray: #4b6247;
  --color-cornflowerblue: #1ca9fe;

  /* Paddings */
  --padding-mid: 17px;

  /* Border radiuses */
  --br-sm: 14px;
  --br-lg: 18px;
  --br-xl: 20px;
  --br-6xl: 25px;
  --br-11xl: 30px;
  --br-24xl: 43px;
  --br-980xl: 999px;
  --br-9980xl: 9999px;

  --vh: 1vh;
}
