.imgWrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .imgWrapper {
    img {
      width: 100vw;
    }
  }
}
